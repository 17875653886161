// eslint-disable-next-line no-use-before-define

import 'react-datepicker/dist/react-datepicker.css';
import 'css/fonts.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AppProps } from 'next/app';
import React, { ReactElement } from 'react';
import { createIntl, createIntlCache, RawIntlProvider, ResolvedIntlConfig } from 'react-intl';
import { Provider } from 'react-redux';

import { DesignSystemProvider, PortalTheme } from '@calm-web/design-system';

import Auth0Provider from '@/components/providers/Auth0Provider';
import createStore from '@/store/createStore';

declare global {
	interface Window {
		fbAsyncInit?: () => void;
	}
}

const cache = createIntlCache();
const store = createStore();

const stripePromise = process.env.STRIPE_TOKEN ? loadStripe(process.env.STRIPE_TOKEN) : null;

function MyApp({
	Component,
	pageProps,
}: AppProps<{
	messages: ResolvedIntlConfig['messages'];
}>): ReactElement {
	const { messages } = pageProps;
	const locale = 'en';

	const intl = createIntl({ locale, messages }, cache);

	return (
		<Provider store={store}>
			<RawIntlProvider value={intl}>
				<DesignSystemProvider theme={PortalTheme}>
					<Auth0Provider>
						<Elements stripe={stripePromise}>
							<Component {...pageProps} />
						</Elements>
					</Auth0Provider>
				</DesignSystemProvider>
			</RawIntlProvider>
		</Provider>
	);
}

export default MyApp;
